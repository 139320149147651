import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';
import { NetworkStatus } from 'apollo-client';
import type { GraphQLError } from 'graphql';
import { styled } from '@compiled/react';

import Spinner from '@atlaskit/spinner/spinner';
import { token } from '@atlaskit/tokens';

import {
	SPACE_PAGES_PAGINATE_EXPERIENCE,
	SPACE_PAGES_EXPERIENCE,
	SPACE_PAGES_FILTER_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { PageCards } from '@confluence/page-card';
import { usePageSpaceKey } from '@confluence/page-context';
import { PageLoadEnd } from '@confluence/browser-metrics';
import { useSessionData } from '@confluence/session-data';
import { ErrorDisplay } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { ViewportObserver } from '@confluence/viewport-observer';
import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';
import { hashDataWithSha1_DO_NOT_USE } from '@confluence/hash';
import { useIsFolderEnabled } from '@confluence/folder-utils/entry-points/useIsFolderEnabled';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';
import { fg } from '@confluence/feature-gating';

import { SPACE_PAGES_PAGE_LOAD } from '../perf.config';
import { SpacePagesQuery } from '../SpacePagesQuery.graphql';
import type {
	SpacePagesQuery as SpacePagesQueryType,
	SpacePagesQueryVariables,
} from '../__types__/SpacePagesQuery';
import {
	ConfluenceSearchContentStatus,
	LabelNamespaceEnum,
	ConfluenceContentSearchScope,
} from '../__types__/SpacePagesQuery';
import { transformData } from '../transformers';
import { hasFilters, SpacePagesContext } from '../SpacePagesContext';
import { useSpacePagesAnalyticsEvents } from '../hooks';

import { NoResults } from './NoResults';
import { Empty } from './Empty';
import { GenericError, PaginationError } from './Error';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchResultsContainer = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverlaySpinnerContainer = styled.div({
	position: 'absolute',
	top: '100px',
	left: '48%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResultCount = styled.div({
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	color: token('color.text.subtlest'),
	position: 'relative',
	margin: '0 0 10px 0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PaginationSpinnerContainer = styled.div({
	width: '100%',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '30px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '30px',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LoadingOverlay = styled.div({
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	backgroundColor: token('elevation.surface.overlay', 'white'),
	opacity: token('opacity.loading', '0.5'),
});

const SEARCH_PAGE_SIZE = 24;
const RECOMMENDED_PAGE_SIZE = 12;

const isDeleteError = (error: GraphQLError): boolean =>
	error.message.includes('com.atlassian.confluence.api.service.exceptions.NotFoundException');

const i18n = defineMessages({
	resultCount: {
		id: 'space-pages.search.results.found.count',
		defaultMessage: '{count, plural, one {# page found} other {# pages found}}',
		description:
			"Text that appears above the search results on a space's Pages screen when a text search or filter is applied, indicating how many results were found.",
	},
	untitledDraftText: {
		id: 'space-pages.untitled-draft',
		defaultMessage: 'Untitled',
		description:
			'A placeholder for drafts that have no title for the page cards in the space-level pages view',
	},
});

/**
 * This error can be reproduced by adding restrictions to a page,
 * then quickly refreshing Pages in another tab as another user.
 * The page you just restricted will get this error. We mark it as handled
 * since it correctly fails the permission check.
 */
const isParentRestrictedError = (error: GraphQLError): boolean =>
	error.message.includes(
		'com.atlassian.confluence.api.service.exceptions.PermissionException: Parent page view is restricted',
	);

/**
 * This error can be reproduced when a user is searching in a stale container when they do not have proper perms.
 * To reproduce, open two Space Pages tabs. Log out of confluence from one of the tabs, and search in the Space Pages of the other.
 * You should get a network call with a 200 status code and an error message 403 forbidden.
 * It is thrown by api_search intentionally and counted as user errors, and thus is expected and should not affect our reliability metrics.
 */
const isExpected403Error = (error: ApolloError): boolean =>
	error.graphQLErrors.some((error) =>
		error.message.includes('Problem while calling endpoint api_search: HTTP 403 Forbidden.'),
	);

export const isExpectedError = (error: ApolloError) =>
	error.graphQLErrors.every(
		(gqlError) => isDeleteError(gqlError) || isParentRestrictedError(gqlError),
	);

type SearchResultsProps = {
	spaceId?: string;
};

export const SearchResults = ({ spaceId }: SearchResultsProps) => {
	const intl = useIntl();
	const { appearance, filters, sortBy, persistenceQueryLoading } = useContext(SpacePagesContext);
	const { text: searchText, pagesFilter } = filters;
	const [stateSpaceKey] = usePageSpaceKey();
	// @ts-ignore FIXME: `stateSpaceKey` can be `undefined` here, and needs proper handling
	const spaceKey: string = stateSpaceKey;
	const { userId: currentUserId, isLicensed } = useSessionData();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [pageLoadStopTime, setPageLoadStopTime] = useState<number | null>(null);
	const { createAnalyticsEvent } = useSpacePagesAnalyticsEvents(spaceId);
	const includeRecommended: boolean = Boolean(
		!searchText && !sortBy && spaceId && pagesFilter === 'all',
	);

	const { isWhiteboardFeatureEnabled } = useIsWhiteboardFeatureEnabled();
	const isWhiteboardEnabled = isWhiteboardFeatureEnabled('whiteboardsEnabled');

	const isDatabaseEnabled = isContentTypeEnabledInCurrentEnv('database');
	const { isFolderEnabled } = useIsFolderEnabled();

	/**
	 * WARNING IF MAKING CHANGES TO VARIABLES
	 * Any changes to the variables object need to be reflected in
	 * preloadSpacePages, otherwise preloading will not work properly
	 */
	const variables = useMemo(
		() => ({
			searchText,
			first: SEARCH_PAGE_SIZE,
			maxNumberOfResults: RECOMMENDED_PAGE_SIZE,
			filters: {
				spaces: { spaceKeys: [spaceKey] },
				titleMatchOnly: { titleMatchOnly: true },
				...(pagesFilter === 'worked-on' &&
					currentUserId &&
					isLicensed && {
						contributors: { accountIds: [currentUserId] },
					}),
				...(pagesFilter === 'starred' &&
					isLicensed && {
						labels: [{ namespace: LabelNamespaceEnum.MY, name: 'favourite' }],
					}),
				statuses: {
					statuses: [ConfluenceSearchContentStatus.CURRENT, ConfluenceSearchContentStatus.DRAFT],
				},
			},
			sortBy: sortBy ? [sortBy] : sortBy,
			isLicensed,
			includeRecommended,
			spaceId,
			scopes: [
				ConfluenceContentSearchScope.PAGE,
				ConfluenceContentSearchScope.EMBED,
				...(isWhiteboardEnabled ? [ConfluenceContentSearchScope.WHITEBOARD] : []),
				...(isDatabaseEnabled ? [ConfluenceContentSearchScope.DATABASE] : []),
				...(isFolderEnabled ? [ConfluenceContentSearchScope.FOLDER] : []),
			],
		}),
		[
			searchText,
			spaceKey,
			pagesFilter,
			sortBy,
			currentUserId,
			isLicensed,
			includeRecommended,
			spaceId,
			isWhiteboardEnabled,
			isDatabaseEnabled,
			isFolderEnabled,
		],
	);

	const {
		data,
		loading,
		error: firstPageError,
		networkStatus,
		fetchMore,
	} = useQuery<SpacePagesQueryType, SpacePagesQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpacePagesQuery,
		{
			variables,
			errorPolicy: 'all',
			notifyOnNetworkStatusChange: true,
		},
	);

	const [nextPageError, setNextPageError] = useState<ApolloError>();

	const { hasNextPage, nextPageToken } = data?.confluenceContentSearch.pageInfo || {};

	const nextPage = useCallback(async () => {
		if (hasNextPage && nextPageToken && !loading) {
			experienceTracker.start({ name: SPACE_PAGES_PAGINATE_EXPERIENCE });
			const { errors } = await fetchMore({
				variables: {
					...variables,
					token: nextPageToken,
				},
				updateQuery: (prevResult: SpacePagesQueryType, { fetchMoreResult }) => {
					if (!fetchMoreResult) {
						return prevResult;
					}
					fetchMoreResult.confluenceContentSearch.nodes =
						prevResult.confluenceContentSearch.nodes.concat(
							fetchMoreResult.confluenceContentSearch.nodes,
						);
					return fetchMoreResult;
				},
			});
			errors &&
				setNextPageError({
					name: 'PaginationError',
					message: errors.map((e) => e.message).join('\n'),
					graphQLErrors: errors,
					networkError: null,
					extraInfo: null,
				});
		}
	}, [hasNextPage, nextPageToken, loading, variables, fetchMore, experienceTracker]);

	const untitledDraftText = intl.formatMessage(i18n.untitledDraftText);
	const searchResults = useMemo(
		() => transformData(data, untitledDraftText, fg('confluence_frontend_whiteboard_emoji_titles')),
		[data, untitledDraftText],
	);

	const totalResultCount = data?.confluenceContentSearch.totalCount;

	useEffect(() => {
		!loading &&
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'updated',
					actionSubject: 'spacePages',
					attributes: {
						noResultsFetched: searchResults.length === 0,
						resultCount: searchResults.length,
						recommendedCount: (data?.objectRecommendations?.nodes || []).length,
						textFilter: searchText.length !== 0,
						wordCount: (searchText.match(/\w+/g) || []).length,
						queryLength: searchText.length,
						queryHash: hashDataWithSha1_DO_NOT_USE(searchText),
						pagesFilter,
						sort: sortBy,
						view: appearance,
					},
				},
			}).fire();
	}, [
		createAnalyticsEvent,
		loading,
		searchResults,
		data,
		searchText,
		pagesFilter,
		sortBy,
		appearance,
	]);

	const error = useMemo(() => {
		if (firstPageError) {
			// handle 403 error so that it doesn't impact reliability, but we still don't want to show the results to the user
			if (isExpected403Error(firstPageError)) {
				markErrorAsHandled(firstPageError);
				[SPACE_PAGES_EXPERIENCE, SPACE_PAGES_FILTER_EXPERIENCE].forEach((name) => {
					experienceTracker.abort({
						name,
						reason: 'User does not have authentication to search',
					});
				});
				return firstPageError;
			} else if (isExpectedError(firstPageError)) {
				markErrorAsHandled(firstPageError);
			} else {
				return firstPageError;
			}
		}
		if (nextPageError) {
			if (isExpected403Error(nextPageError)) {
				experienceTracker.abort({
					name: SPACE_PAGES_PAGINATE_EXPERIENCE,
					reason: 'User does not have authentication to search',
				});
				markErrorAsHandled(nextPageError);
				return nextPageError;
			} else if (isExpectedError(nextPageError)) {
				markErrorAsHandled(nextPageError);
			} else {
				return nextPageError;
			}
		}
		return undefined;
	}, [firstPageError, nextPageError, experienceTracker]);

	useEffect(() => {
		if (!loading) {
			setPageLoadStopTime(performance.now());
			[
				SPACE_PAGES_EXPERIENCE,
				SPACE_PAGES_FILTER_EXPERIENCE,
				SPACE_PAGES_PAGINATE_EXPERIENCE,
			].forEach((name) => {
				if (error) {
					experienceTracker.stopOnError({ name, error });
				} else {
					experienceTracker.succeed({ name });
				}
			});
		}
	}, [loading, searchResults, error, experienceTracker, setPageLoadStopTime]);
	const errorMessage = hasFilters(filters) ? (
		<NoResults />
	) : (
		<Empty isWhiteboardEnabled={isWhiteboardEnabled} />
	);

	return (
		<SearchResultsContainer
			role="tabpanel"
			id={`panel-${pagesFilter}`}
			aria-labelledby={`tab-${pagesFilter}`}
		>
			{networkStatus === NetworkStatus.ready &&
			searchResults.length !== 0 &&
			!error &&
			(pagesFilter !== 'all' || searchText.length !== 0) ? (
				<ResultCount>
					<FormattedMessage {...i18n.resultCount} values={{ count: totalResultCount }} />
				</ResultCount>
			) : null}
			{!error && !persistenceQueryLoading ? (
				<PageCards
					appearance={appearance}
					nodes={searchResults}
					analyticsData={{
						source: 'spacePagesScreen',
						attributes: {
							sort: sortBy,
						},
					}}
				/>
			) : null}
			{/*empty states*/}
			{networkStatus === NetworkStatus.ready && searchResults.length === 0 && !error
				? errorMessage
				: null}
			{/*loading states*/}
			{networkStatus === NetworkStatus.setVariables ? ( // when variables are updated (filtering, sorting)
				<Fragment>
					<LoadingOverlay />
					<OverlaySpinnerContainer>
						<Spinner size="large" testId="overlay-spinner" />
					</OverlaySpinnerContainer>
				</Fragment>
			) : networkStatus === NetworkStatus.loading || // query has never been run before and the query is now currently running
			  (hasNextPage && !error) ? ( // render the spinner even when not loading to prevent jumping
				<PaginationSpinnerContainer>
					<Spinner size="large" testId="pagination-spinner" />
				</PaginationSpinnerContainer>
			) : null}
			{/* Pagination sentinel - when this scrolls into view, the next page loads. If there is no next page, don't need to render */}
			{!error && hasNextPage ? (
				<ViewportObserver onViewportEnter={nextPage}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
					<div style={{ position: 'absolute', bottom: '0', height: '300px' }} />
				</ViewportObserver>
			) : null}
			{/*error states*/}
			{error ? (
				<ErrorDisplay error={error}>
					{!isExpected403Error(error) && (nextPageError || searchResults.length) ? (
						<PaginationError />
					) : (
						<GenericError />
					)}
				</ErrorDisplay>
			) : null}
			{pageLoadStopTime && (
				<PageLoadEnd metric={SPACE_PAGES_PAGE_LOAD} stopTime={pageLoadStopTime} />
			)}
		</SearchResultsContainer>
	);
};
