import type { FC } from 'react';
import React from 'react';

import type { IconColor } from '@atlaskit/tokens/css-type-schema';

import { Compact24Icon } from './images/Compact24Icon';

type Size = 'medium';

type CompactIconProps = {
	label: string;
	size?: Size;
	primaryColor?: IconColor;
	testId?: string;
};

export const CompactIcon: FC<CompactIconProps> = ({ label, size, primaryColor, testId }) => {
	return React.createElement(Compact24Icon, {
		size,
		label,
		testId,
		primaryColor,
	});
};
