import type { FC } from 'react';
import React from 'react';

import type { IconColor } from '@atlaskit/tokens/css-type-schema';

import { List24Icon } from './images/List24Icon';

type Size = 'medium';

type ListIconProps = {
	label: string;
	size?: Size;
	primaryColor?: IconColor;
	testId?: string;
};

export const ListIcon: FC<ListIconProps> = ({ label, size, primaryColor, testId }) => {
	return React.createElement(List24Icon, {
		size,
		label,
		testId,
		primaryColor,
	});
};
